import { TLinksConfig } from '../../types/links';

export const LINKS_CONFIG: TLinksConfig = {
  help: {
    href: 'https://hc.cian.ru/hc/ru',
    text: 'Справочный центр',
    label: 'help',
  },
  legalDocuments: {
    href: '/legal-documents/pravila_polzovaniya_sajtom_cian_0/',
    text: 'Юридические документы',
    label: 'legal-documents',
  },
  price: {
    href: '/price/?from=footer',
    text: 'Тарифы и цены',
    label: 'price',
  },
  advert: {
    href: '/help/re/advert/',
    text: 'Реклама на сайте',
    label: 'advert',
  },
  superAgent: {
    href: 'https://support.cian.ru/ru/knowledge_base/article/5311/category/69/',
    text: 'Программа «Суперагенты»',
    label: 'super_agent',
  },
  promo: {
    href: '/promo/adv/',
    text: 'Продвижение',
    label: 'promo',
  },
  auction: {
    href: '/help/auction/',
    text: 'Аукцион',
    label: 'auction',
  },
  searchOnMap: {
    href: '/map/',
    text: 'Поиск на карте',
    label: 'search_on_map',
  },
  tv: {
    href: '/tv/',
    text: 'Реклама Циана на ТВ',
    label: 'tv',
  },
  vacancies: {
    href: '/vacancies-realtors/',
    text: 'Вакансии агентств',
    label: 'vacancies',
  },
  mortgageCalculator: {
    href: '/ipoteka/calculator/',
    text: 'Ипотечный калькулятор',
    label: 'mortgage-calculator',
  },
  legalCheck: {
    href: '/legal-check/?source=footer',
    text: 'Проверка недвижимости',
    label: 'legal-check',
  },
  sitemap: {
    href: '/sitemap/',
    text: 'Карта сайта',
    label: 'sitemap',
  },
  newOffers: {
    href: '/svezhie-obyavleniya/',
    text: 'Свежие объявления',
    label: 'svezhie-obyavleniya',
  },
  career: {
    href: '/vacancies/',
    text: 'Карьера в Циане',
    label: 'career',
  },
  contacts: {
    href: '/contacts/',
    text: 'Помощь',
    label: 'contact',
  },
  irLink: {
    href: 'http://ir.ciangroup.ru/',
    text: 'Сайт для инвесторов',
    label: 'investors',
  },
};
