import { applyMiddleware, CombinedState, combineReducers, compose, createStore } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { IApplicationState, TApplicationStateInitial, TReduxActions } from '../../types/redux';
import { IApplicationContext } from '../../types/applicationContext';
import {
  footerTypeReducer,
  platformReducer,
  showSiteTypeChangingReducer,
  showAdditionalSeoLinksReducer,
  sectionReducer,
  downloadAppBannerReducer,
} from '../../reducers';

interface IThunkExt {
  dispatch: ThunkDispatch<IApplicationState, IApplicationContext, TReduxActions>;
}

/* istanbul ignore next */
const composeEnhancers = (() => {
  if (process.env.NODE_ENV === 'development' && process.env.IS_BROWSER_BUILD) {
    const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;

    if (__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      return __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: `frontend-footer# ${document.title}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
  }

  return compose;
})();

/**
 * Хелпер для создания Redux стора
 * @param {IApplicationState} initialState - предзагруженное состояние
 * @param {IApplicationContext} context
 */
export function createReduxStore(initialState: TApplicationStateInitial, context: IApplicationContext) {
  const reducers = combineReducers<IApplicationState, TReduxActions>({
    footerType: footerTypeReducer,
    platform: platformReducer,
    section: sectionReducer,
    showSiteTypeChanging: showSiteTypeChangingReducer,
    showAdditionalSeoLinks: showAdditionalSeoLinksReducer,
    downloadAppBannerDisabled: downloadAppBannerReducer,
  });

  const storeEnhancers = composeEnhancers(applyMiddleware(thunk.withExtraArgument(context)));

  return createStore<CombinedState<IApplicationState>, TReduxActions, IThunkExt, {}>(
    reducers,
    initialState,
    storeEnhancers,
  );
}
